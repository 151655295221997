import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import AboutMain from "./components/About/AboutMain";
import Footer from "./components/Footer/Footer";
import "./App.css";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route path="/about" element={<AboutMain />} />
        {/* <Route path="/services" component={Services} /> */}
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
