export const teamData = [
  {
    id: "1",
    image: "./images/photo-1.jpeg",
    name: "Mr. Nobody",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Provident ipsum tenetur eligendi, suscipit ratione totam vel officia neque veritatis in.",
  },
  {
    id: "2",
    image: "./images/photo-2.jpeg",
    name: "Mr.Somebody",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Provident ipsum tenetur eligendi, suscipit ratione totam vel officia neque veritatis in.",
  },
  {
    id: "3",
    image: "./images/photo-3.jpeg",
    name: "Mr. Test",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Provident ipsum tenetur eligendi, suscipit ratione totam vel officia neque veritatis in.",
  },
];
