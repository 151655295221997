const data = [
  {
    title: "First Success",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab fuga eos doloribus? Deleniti sed hic blanditiis id possimus explicabo. Voluptates repellat vero asperiores vel accusantium molestias, sapiente expedita amet id doloremque odio suscipit rem minus ab numquam",
  },

  {
    title: "Establishment",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab fuga eos doloribus? Deleniti sed hic blanditiis id possimus explicabo. Voluptates repellat vero asperiores vel accusantium molestias, sapiente expedita amet id doloremque odio suscipit rem minus ab numquam",
  },
  {
    title: "Partnership",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab fuga eos doloribus? Deleniti sed hic blanditiis id possimus explicabo. Voluptates repellat vero asperiores vel accusantium molestias, sapiente expedita amet id doloremque odio suscipit rem minus ab numquam",
  },

  {
    title: "National Recognition",
    content:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Ab fuga eos doloribus? Deleniti sed hic blanditiis id possimus explicabo. Voluptates repellat vero asperiores vel accusantium molestias, sapiente expedita amet id doloremque odio suscipit rem minus ab numquam",
  },
];
export default data;
