import React from "react";
import Fade from 'react-reveal/Fade';
import {
  BottomTitle,
  ServiceTitle,
  ServiceDescription,
  SingleService,
} from "./ServiceDetails.elements";
const ServiceDetails = ({ bottomData }) => {
  return (
    <div>
      <BottomTitle>
        {bottomData.map((singleData) => (
          <Fade left>
            <SingleService>
              <ServiceTitle>{singleData.title}</ServiceTitle>
              <ServiceDescription>{singleData.content}</ServiceDescription>
            </SingleService>
          </Fade>
        ))}
      </BottomTitle>
    </div>
  );
};

export default ServiceDetails;
